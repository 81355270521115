import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Home/ContentServicesHome";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockFour from "../components/block/BlockFour";
import BlockPrincipal from "../components/block/Block_6";
import BlockOne from "../components/block/BlockOne";
import VideoPromocional from "../components/global/VideoPromocional";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">

        <Modal />

        <HeroSection />

        <BlockPrincipal
          text={rpdata?.dbHome?.[0].text}
          image1={rpdata?.gallery?.[1]}
          image2={rpdata?.gallery?.[12]}
          image3={rpdata?.gallery?.[9]}
          image4={rpdata?.gallery?.[5]}
          sloganPrincipal={true}
          listsServices={true}
        />

        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
            />
            : null
        }

        <Directories />

        <BlockFour
          title={rpdata?.dbSlogan?.[4]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.gallery?.[7]}
          image2={rpdata?.gallery?.[13]}
          image3={rpdata?.gallery?.[11]}
        />

        <CounterUp image={rpdata?.stock?.[2]} />

        <div className="relative">
          <BlockOne
            title={'a little about us'}
            text={rpdata?.dbAbout?.[0]?.text}
            image={rpdata?.gallery?.[2]}
            listsAbout={true}
          />
          <div className="w-full absolute top-0 right-1 md:right-5 flex justify-end">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/stock%2F17%20%20YEARS-01.png?alt=media&token=8a996c96-8f3a-4429-aca7-f79f8badd528"
              alt="no found"
              className="w-[50%] md:w-[30%] lg:w-[20%]"
            />
          </div>
        </div>

        {/* our reviews */}
        {
          rpdata?.reviews?.isHomeOnly === true ?
            <div className="w-4/5 mx-auto py-[100px]">
              <h1 className="pb-10 text-center"> Our Reviews</h1>
              <div className={`${rpdata?.reviews?.links?.[0]}`} style={{ overflow: 'hidden' }}></div>
            </div>
            : null
        }

        <ServicesHome />

        {/* Paleta de Colores */}

        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.gallery?.[9]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>

        <Map />

      </div>
    </BaseLayout>
  );
}

export default Home;
